import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import DownloadGrid from "../../components/DownloadGrid/DownloadGrid";
import Eyecatcher from "../../components/Eyecatcher/Eyecatcher";
import MediaCard from "../../components/MediaCard/MediaCard";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import QuickFactsLink from "../../components/QuickFactsLink/QuickFactsLink";
import Sharing from "../../components/Sharing/Sharing";
import VideoPreview from "../../components/VideoPreview/VideoPreview";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";
import GetIconComponents from "../utils/GetIconComponents";

const ProgramPage = ({ data: { page } }) => {
  const { t } = useTranslation();

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <DetailPageHeader
        headline={page.title.trim()}
        image={page.images?.images?.length > 0 && page.images?.images[0]?.asset && (
          <SanityImage
            {...page.images.images[0]}
            width={800}
            className="w-full"
          />

        )}
        sharing={(
          <Sharing
            shareTitle={page.title}
            title={`${t("share")}:`}
          />
        )}
        type={t(page.targetAudience)}
      />
      <div className="grid grid-cols-12 gap-x-12">
        {/* Content */}
        <div className="col-span-12 order-2 lg:order-1 lg:col-span-9 mx-18">
          <div>
            {page._rawDescription && (
              <div className="grid grid-cols-12 lg:grid-cols-9">
                <div className="col-span-12 lg:col-span-8">
                  <BlockContent blocks={page._rawDescription} />
                </div>
              </div>
            )}
            {page.eyecatcher?._rawText
              && (
                <div className="mt-90">
                  <Eyecatcher
                    headline={page.eyecatcher?.headline}
                    button={
                      page.eyecatcher?.callToAction
                      && (
                        <Button
                          color="white"
                          title="Button"
                          href={GetCallToActionContext(page.eyecatcher).path}
                        >{page.eyecatcher?.callToAction?.title}
                        </Button>
                      )
                    }
                    description={<BlockContent blocks={page.eyecatcher?._rawText} />}
                    logo={
                      page.eyecatcher?.Icon?.component
                      && React.createElement(
                        GetIconComponents().components[page.eyecatcher?.Icon?.component],
                      )
                    }
                  />
                </div>
              )}
            {page.videos?.length > 0 && (
              <div className="text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42 mx-18">
                <DownloadGrid title={t("videos")}>
                  {page.videos?.map((video) => (
                    <MediaCard>
                      <VideoPreview
                        previewImageUrl={video.thumbnail}
                        videoUrl={video.url}
                      />
                    </MediaCard>
                  ))}
                </DownloadGrid>
              </div>
            )}
          </div>
        </div>
        {/* Quick Facts */}
        <div className="col-span-12 order-1 mb-40 lg:order-2 lg:col-span-3 lg:mb-0 mx-18">
          <QuickFacts>
            {page.furtherLinks.length > 0 && (
              <QuickFactsItem title={t("furtherLinks")}>
                {page.furtherLinks?.map((link) => (
                  <QuickFactsLink data={link} />
                ))}
              </QuickFactsItem>
            )}
          </QuickFacts>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityProgram(id: {eq: $id}) {
      id
      title
      furtherLinks {
        title
        linkType
        href
        _rawInternalLink
        file {
          asset {
            assetId
            path
            url
          }
        }
      }
      Metadata {
        ...MetaData
      }
      targetAudience
      type
      videos {
        url
        thumbnail
        service
        id
      }
      images {
        images {
          ...ImageWithPreview
          alt
        }
      }
      _rawDescription
      eyecatcher {
        headline
        _rawText
        Icon {
          component
          icon {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR, width: 720)
            }
          }
        }
        callToAction {
          linkType
          blank
          title
          href
          _rawInternalLink(resolveReferences: {maxDepth: 1})
        }
      }
    }
  }
`;

ProgramPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ProgramPage;
